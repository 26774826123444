export const MTRacing = require('./media/MT_racing.jpg')
export const  MTCampus = require('./media/MT_campus.jpg')
export const MTDogs = require('./media/MT_dogs.jpg')
export const MTMex = require('./media/MT_mex.jpg')
export const MTCar = require('./media/MT_car.jpg')
export const TSLogo = require('./media/png-transparent-typescript-hd-logo-thumbnail.png')
export const PyLogo = require('./media/Python-logo-notext-rotate-flip.png')
export const ReactLogo = require('./media/React-icon.png')
export const SQLLogo = require('./media/sql-logo.png')
export const SwiftLogo = require('./media/swift.png')
export const FlaskLogo = require('./media/flask-logo-png-transparent.png')
export const GitLogo = require('./media/github-logo.png')
export const HTMLLogo = require('./media/html-logo.png')

